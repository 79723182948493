import React from "react"
import { PrivacyCookiesSection, PrivacyHeroSection, PrivacyPolicySection, PrivacyThirdpartySection } from "@/layout"

const PrivacyPage: React.FC = () => {
    return (
        <div className={'container-privacy'}>
            <PrivacyHeroSection/>
            <PrivacyPolicySection/>
            <PrivacyCookiesSection/>
            <PrivacyThirdpartySection/>
        </div>
    )
}

export default PrivacyPage
